/* global  */ // tUrl等のグローバル変数を宣言する
// import Swiper from 'swiper' // Swiper
// import PerfectScrollbar from 'perfect-scrollbar' // 独自スクロールバー
// position:styckyのポリフィル
// Stickyfill.add(document.querySelectorAll('.sticky'))

jQuery(function ($) {
  $('#sp-menu-open').click(function () {
    $('.header__frame').toggleClass('fixed')
  })
  $('.spNav__box').click(function () {
    $('.header__frame').removeClass('fixed')
  })
})
